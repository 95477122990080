// eslint-disable-next-line no-restricted-imports
import fs from 'fs';
import {
  RadarEntry,
  RadarQuadrant,
  RadarRing,
  TechRadarApi,
  TechRadarLoaderResponse,
} from '@backstage/plugin-tech-radar';

export class MyOwnTechRadarClient implements TechRadarApi {
  async load(): Promise<TechRadarLoaderResponse> {
    const dataPath = `${__dirname}/datafiles/techradar.json`;
    return new Promise<TechRadarLoaderResponse>((resolve, reject) => {
      fs.readFile(dataPath, 'utf-8', (error, data) => {
        if (error) {
          reject(error);
          return;
        }
        const jsonData = JSON.parse(data);
        const entries: Array<RadarEntry> = jsonData.entries;
        const quadrants: Array<RadarQuadrant> = jsonData.quadrants;
        const rings: Array<RadarRing> = jsonData.rings;
        const techRadarData: TechRadarLoaderResponse = {
          entries,
          quadrants,
          rings,
        };
        resolve(techRadarData);
      });
    });
  }
}
